export const linerDetail = [
	{
		id: 1,
		defalut: require('@/assets/img/眼部素材/产品模特图/YXB1.jpg'),
		active: require('@/assets/img/眼部素材/产品模特图/YXB2.jpg'),
		series: '眼部',
		seriesName: '水粉笔',
		zhName: '酵色「水粉笔」',
		enName: 'Liquid Eye-liner',
		intro: '柔雾哑光质地 奶油感红唇',
		price: '59',
		of: '1',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/眼线液笔/小图/4.jpg'), bigUrl: require('@/assets/img/详情/眼线液笔/大图/04.jpg') },
			{ index: 2, url: require('@/assets/img/详情/眼线液笔/小图/2.jpg'), bigUrl: require('@/assets/img/详情/眼线液笔/大图/02.jpg') },
			{ index: 3, url: require('@/assets/img/详情/眼线液笔/小图/3.jpg'), bigUrl: require('@/assets/img/详情/眼线液笔/大图/03.jpg') },
			{ index: 3, url: require('@/assets/img/详情/眼线液笔/小图/1.jpg'), bigUrl: require('@/assets/img/详情/眼线液笔/大图/01.jpg') },
		],
		color: [
			{
				active: '#a09470',
				name: '#01 听茶',
				span1: '特调橄榄绿',
				span2: '灵动点缀 打破沉闷 ',
				color: require('@/assets/img/详情/眼线液笔/01.jpg')
			},
			{
				active: '#c0888e',
				name: '#02 酒渍',
				span1: '冷调灰紫色',
				span2: '微醺甜酒',
				color: require('@/assets/img/详情/眼线液笔/02.jpg')
			},
			{
				active: '#776362',
				name: '#03 微尘',
				span1: '清透灰蓝调',
				span2: '低调高级',
				color: require('@/assets/img/详情/眼线液笔/03.jpg')
			},
			{
				active: '#b07574',
				name: '#04 烟霞',
				span1: '藕粉豆沙色',
				span2: '眼间温柔',
				color: require('@/assets/img/详情/眼线液笔/04.jpg')
			},
			{
				active: '#c37f67',
				name: '#05 花间',
				span1: '木质苦橙色',
				span2: '暖调烘焙感',
				color: require('@/assets/img/详情/眼线液笔/05.jpg')
			},
			{
				active: '#906f62',
				name: '#06 木偶',
				span1: '松露灰棕色',
				span2: '低饱和下的丰富层次',
				color: require('@/assets/img/详情/眼线液笔/06.jpg')
			}
		]
	}
]